import React, {Component} from 'react';
import Chart from "react-google-charts";
import Ajax from "../../common/ajax";
import Config from "../../config";
import Waiting from "../../common/containers/Waiting";
import PrivatePage from "../../common/containers/PrivatePage";
import Content from "../../common/containers/Content";

class CheckoutsReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            convertionsPerMonth: null,

            checkouts: {},

            checkoutsPerUF: null,
            checkoutsPerBroaderArea: null,
            checkoutsPerMonth: null,
            checkoutsPerYear: null,
            checkoutsPerSemester: null,

            paidCheckoutsPerMonth: null,
            paidCheckoutsPerWeek: null,
            paidCheckoutsPerDayReais: null,
            paidCheckoutsPerHour: null,

            checkoutCategories: null,
            checkoutTime: null,
        };
    }

    componentDidMount() {
        this.fetchCheckouts();

        this.fetchConvertionsPerMonth();

        this.fetchCheckoutsPerUF();
        this.fetchCheckoutsPerBroaderArea();
        this.fetchCheckoutsPerYear();
        this.fetchCheckoutsPerSemester();
        this.fetchCheckoutsPerMonth();

        this.fetchPaidCheckoutsPerMonth();
        this.fetchPaidCheckoutsPerWeek();
        this.fetchPaidCheckoutsPerDayReais();
        this.fetchPaidCheckoutsPerHour();

        this.fetchCheckoutCategories();
        this.fetchCheckoutTime();
    }

    fetchCheckouts() {
        Ajax.get(`${Config.djangoHost}r/checkouts`).done(data => {
            this.setState(state => ({...state, checkouts: data}));
        });
    }

    fetchConvertionsPerMonth() {
        Ajax.get(`${Config.djangoHost}r/convertion-per-month`).done(data => {
            this.setState(state => ({...state, convertionsPerMonth: data}));
        });
    }

    fetchCheckoutsPerUF() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-uf`).done(data => {
            this.setState(state => ({...state, checkoutsPerUF: data}));
        });
    }

    fetchCheckoutsPerBroaderArea() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-broader-area`).done(data => {
            this.setState(state => ({...state, checkoutsPerBroaderArea: data}));
        });
    }

    fetchCheckoutsPerYear() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-year`).done(data => {
            this.setState(state => ({...state, checkoutsPerYear: data}));
        });
    }

    fetchCheckoutsPerSemester() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-semester`).done(data => {
            this.setState(state => ({...state, checkoutsPerSemester: data}));
        });
    }

    fetchCheckoutsPerMonth() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-month`).done(data => {
            this.setState(state => ({...state, checkoutsPerMonth: data}));
        });
    }

    fetchPaidCheckoutsPerMonth() {
        Ajax.get(`${Config.djangoHost}r/paid-checkouts-per-month`).done(data => {
            this.setState(state => ({...state, paidCheckoutsPerMonth: data}));
        });
    }

    fetchPaidCheckoutsPerWeek() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-week`).done(data => {
            this.setState(state => ({...state, paidCheckoutsPerWeek: data}));
        });
    }

    fetchPaidCheckoutsPerDayReais() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-day-reais`).done(data => {
            this.setState(state => ({...state, paidCheckoutsPerDayReais: data}));
        });
    }

    fetchPaidCheckoutsPerHour() {
        Ajax.get(`${Config.djangoHost}r/checkouts-per-hour`).done(data => {
            this.setState(state => ({...state, paidCheckoutsPerHour: data}));
        });
    }

    fetchCheckoutCategories() {
        Ajax.get(`${Config.djangoHost}r/checkouts-categories`).done(data => {
            this.setState(state => ({...state, checkoutCategories: data}));
        });
    }

    fetchCheckoutTime() {
        Ajax.get(`${Config.djangoHost}r/checkouts-time`).done(data => {
            this.setState(state => ({...state, checkoutTime: data}));
        });
    }

    renderChart(title, data, props = {type: 'ColumnChart', height: 500, isStacked: true}) {
        const {type = 'ColumnChart', height = 500, isStacked = true} = props;

        const loader = (
            <Waiting isProcessing={true}>
                <div style={{width: height, height: height}}>
                    <h2>{title}...</h2>
                </div>
            </Waiting>
        );

        if (!data) {
            return loader;
        }

        if (type === 'Calendar') {
            const newData = [
                [{ type: 'date', id: 'Date' }, { type: 'number', id: 'R$' }],
            ];
            data.forEach(function (item) {
                newData.push([new Date(item[0]), item[1]]);
            });
            data = newData.slice();
        }

        return (
            <Chart
                height={height}
                chartType={type}
                loader={loader}
                data={data}
                options={{
                    title: title,
                    hAxis: {
                        gridlines: { count: 10 },
                        slantedText:true,
                        slantedTextAngle:90 // here you can even use 180
                    },
                    isStacked: isStacked,
                    legend: { position: "top", maxLines: 3 },
                }}
                legendToggle
            />
        );
    }

    render() {
        return (
            <PrivatePage title="Checkouts Report">
                <Content>
                    <table className="table table-striped table-condensed">
                        <tbody>
                        <tr>
                            <td>All time checkout convertion (%)</td>
                            <td dangerouslySetInnerHTML={{__html: this.state.checkouts.checkout_conversion_rate}}/>
                        </tr>
                        <tr>
                            <td>Finance</td>
                            <td dangerouslySetInnerHTML={{__html: this.state.checkouts.avg_checkout_price}}/>
                        </tr>
                        </tbody>
                    </table>
                    {this.renderChart(
                        "Convertions per month",
                        this.state.convertionsPerMonth)}
                    <br/>
                    {this.renderChart(
                        "Checkouts per year",
                        this.state.checkoutsPerYear)}
                    <br/>
                    {this.renderChart(
                        "Checkouts per semester",
                        this.state.checkoutsPerSemester)}
                    <br/>
                    {this.renderChart(
                        "Checkouts per month",
                        this.state.checkoutsPerMonth)}
                    <br/>
                    {this.renderChart(
                        "Checkouts per month",
                        this.state.paidCheckoutsPerMonth,
                        {type: 'LineChart'})}
                    <br/>
                    {this.renderChart(
                        "Checkouts per week",
                        this.state.paidCheckoutsPerWeek,
                        {type: 'LineChart'})}
                    <br/>
                    {this.renderChart(
                        "Checkouts per hour of day",
                        this.state.paidCheckoutsPerHour)}
                    <br/>
                    {this.renderChart(
                        "Average Price vs. Number of Checkouts (per month)",
                        this.state.checkoutCategories,
                        {type: 'LineChart'})}
                    <br/>
                    {this.renderChart(
                        "Time to Checkout",
                        this.state.checkoutTime)}
                    <br/>
                    {this.renderChart(
                        "Checkouts per day in R$",
                        this.state.paidCheckoutsPerDayReais,
                        {type: 'Calendar', height: 750})}
                    <br/>
                    {this.renderChart(
                        'Checkouts per UF',
                        (this.state.checkoutsPerUF || []).map(i => [`${i[0]}: ${i[2]}%`, i[1]]),
                        {isStacked: true})}
                    <br/>
                    <Chart
                        height={500}
                        chartType="GeoChart"
                        data={(this.state.checkoutsPerUF || []).map(i => [`BR-${i[0].split(' ')[0]}`,i[1], i[2]])}
                        options={{
                            region: 'BR',
                            resolution: 'provinces',
                            title: 'Checkouts per UF',
                        }}
                    />
                    <br/>
                    {this.renderChart(
                        'Checkouts per Broader Area',
                        this.state.checkoutsPerBroaderArea,
                        {isStacked: false})}
                </Content>
            </PrivatePage>
        );
    }
}

export default CheckoutsReportPage;